.loading-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .loading-spinner-container {
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
  }

  .loading-spinner {
    /* Add styles for the CircularProgress component */
    /* For example: */
    color: #000000;
  }

  .loading-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000000;
    margin-right: 5px;
    opacity: 0;
    animation: loading-dot-animation 1.5s infinite;
  }

  @keyframes loading-dot-animation {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .dot:nth-child(2) {
    animation-delay: 0.5s;
  }

  .dot:nth-child(3) {
    animation-delay: 1s;
  }
