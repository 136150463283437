/* Define styles for table1 */
.table1 {
    border: 1px solid #ddd;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }

  /* .table1 th, .table1 td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .table1 th {
    background-color: #f2f2f2;
  } */

  /* Define styles for table2 */
  .table2 {
    border: 2px solid #ddd;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }

  /* .table2 th, .table2 td {
    border: 2px solid #ddd;
    padding: 8px;
  }

  .table2 th {
    background-color: #f2f2f2;
  } */

  /* Define styles for table3 */
  .table3 {
    border: 3px solid #ddd;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }

  /* .table3 th, .table3 td {
    border: 3px solid #ddd;
    padding: 8px;
  }

  .table3 th {
    background-color: #f2f2f2;
  } */

  /* Define styles for table4 */
  .table4 {
    border: 4px solid #ddd;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
  }

  /* .table4 th, .table4 td {
    border: 4px solid #ddd;
    padding: 8px;
  }

  .table4 th {
    background-color: #f2f2f2;
  } */
